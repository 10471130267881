:root {
  --color-primary: #000a8c;
  --color-blue-secondary: #3269c8;
  --color-primary-hover: #4cb98d;
  --color-green-light: #e7ffe8;
  --color-grey-light: #f9f9f9;
  --color-grey-dark: rgba(0,0,0,0.4);
  --color-grey-divider: #f1f1f1;
  --color-font: #333333;
  --color-green: #1CAF5E;
  --color-yellow: #F7B500;
  --color-red: #F14B44;
  --color-blue: #007FFF;
  --color-chip: #e1eefa;
  --color-background: #FAFAFB;
  --color-chip-selected: #b3cff5;
  --grid-width: 940px;
  --top-banner-height: 180px;


  --fc-button-bg-color: #E6F3FA;
  --fc-button-hover-bg-color: #E6F3FA;
  --fc-button-active-bg-color: #E6F3FA;
  --fc-button-text-color: #007FFF;
  --fc-button-border-color: #007FFF;
  --fc-button-hover-border-color: #007FFF;
  --fc-today-bg-color: #E6F3FA;

}


.fc h2.fc-toolbar-title {
  font-size: 1.4em;
}

.fc .fc-col-header-cell-cushion {
  text-transform: capitalize;
  color: var(--color-grey-dark);
  font-size: 1em;
}

.fc .fc-day-today .fc-col-header-cell-cushion {
  color: var(--color-blue)
}

body {
  color: var(--color-font);
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: #FAFAFB;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  font-weight: normal;
}

h6 {
  font-size: 0.77em;
}

h5 {
  font-size: 1em;
}

h4 {
  font-size: 1.33em;
}

h3 {
  font-size: 1.77em;
}

h2 {
  font-size: 2.22em;
}

h1 {
  font-size: 2.66em;
}

p {
  font-size: inherit;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

label {
  font-size: 0.88em;
  color: #666666;
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
  color: inherit;
}
